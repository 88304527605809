// Colors
$primary: #3C4653;
$blue: #39e6e0;
$purple: #4420D8;
$purple-aux: #917DFF;
$yellow: #FFBE44;
$pink: #FF2972;
$white: #FFF;
$black: #000;

// Fonts (use typography.js)
$font-size-base: 16px;
$font-size-h1: 3.375em;
// $font-family-sans-serif: 'Open Sans', sans-serif;
$line-height-base: 1.42;

// Responsive
$screen-xs: 576px;
$screen-sm-min: 768px;
$screen-sm-max: 992px;
$screen-md-max: 1280px;
